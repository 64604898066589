<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
  <div class="row justify-content-md-center" :class="{'loading': !loaded}">
    <!-- INICIO Detalhes -->
    <div class="col-md-3 text-left dark-box">

      <div class="row" >
        <div class="row col-12 mb-5">
          <div  class="row col-12 mb-3">
            <div class="col-12 text-muted">Ref. Royal Cargo:</div>
            <div class="col-12 ">{{ alertData.Numero_Processo }} </div>
          </div>
        </div>

        <div class="row col-12 mb-5">
          <div v-if="alertData.Cliente" class="row col-12 mb-3">
            <div class="col-12 text-muted">Cliente</div>
            <div class="col-12 ">{{ alertData.Cliente }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- FIM Detalhes -->

    <div class="col-9 modal-box">
      <div class="close-button mb-4">
        <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
          <span><i class="text-primary rc-Ativo-36"> </i></span>
        </div>
      </div>
      <div class="row ">
        <div class="col-12 col-md-12">
          <table class="table table-hover align-middle table-clickable">
            <thead>
              <tr>
                <th>
                  Data
                </th>
                <th>
                  Usuário
                </th>
                <th>
                  Situação
                </th>
                <th>
                  Motivo
                </th>
              </tr>
            </thead>
            <tbody v-if="logs">
              <tr v-for="(item, index) in logs.logistica_house_alerta_historico" :key="index">
                <td width="20%">{{ formatDateTime(item.data) }}</td>
                <td>{{ item.id_usuario.full_name }}</td>
                <td width="15%" v-html="formatStatus(item.situacao)"></td>
                <td width="40%" v-html="item.observacao"></td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="4" class="text-center">
                  {{ $t('noting to result') }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import ReportService from '@/services/ReportService'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'ShowLogs',
  props: {
    alertData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    Loading
  },
  data () {
    return {
      isLoading: true,
      fullPage: true,
      loaded: true,
      logs: {}
    }
  },
  created () {
    let me = this
    ReportService.historicAlertStatus(this.alertData)
      .then(function (res) {
        me.logs = res.data.data
        me.isLoading = false
      })
      .catch(() => {
        me.isLoading = false
      })
  },
  methods: {
    onClose () {
      this.$emit('close')
    },
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    formatStatus (value) {
      if (!value) {
        return ' - '
      }

      let statusList = [
        {
          'id': 1,
          'label': this.$i18n.t('form.task.status.active'),
          'color': '#5ec65f'
        },
        {
          'id': 2,
          'label': this.$i18n.t('form.task.status.inactive'),
          'color': '#ababab'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    }
  }
}
</script>

<style scoped>
  .loading {
    pointer-events: none;
    opacity: 0.4;
  }

  .justify-content-md-center {
    padding: 20px;
  }

  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .img-container:hover .img-profile {
    opacity: 0.3;
  }

  .img-container:hover .middle {
    opacity: 1;
  }

  .modal-box{
    padding: 75px;
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }
</style>
